:root {
  --pixel-size: 9px;
}

html {
  max-width: 100%;
  height: 100%;
  /* overflow-x: hidden; */
}

body {
  margin: 0;
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto,
    "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR",
    "Malgun Gothic", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 100%;
  height: 100%;
  /* overflow-x: hidden; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media (hover: none) and (pointer: coarse) {
  body {
    margin: 0;
    font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui,
      Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo",
      "Noto Sans KR", "Malgun Gothic", sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    max-width: 100%;
    max-height: 100vh;
    overflow-x: hidden;
  }
}

@font-face {
  font-family: "DNFBitBitv2";
  font-style: normal;
  font-weight: 400;
  src: url("//cdn.df.nexon.com/img/common/font/DNFBitBitv2.otf")
    format("opentype");
}

.dots_custom {
  position: absolute;
  top: -12px;
  right: 0;
}

.dots_custom li {
  list-style: none;
  cursor: pointer;
  display: inline-block;
  margin: 0 6px;
  padding: 0;
}

.dots_custom li button {
  border: none;
  background: #c4c4c4;
  color: transparent;
  cursor: pointer;
  display: block;
  height: 6px;
  width: 6px;
  border-radius: 100%;
  padding: 0;
}

.dots_custom li.slick-active button {
  background-color: #6d6d6d;
}

#cms-cta p {
  white-space: pre-wrap;
  margin: 0;
}

.healthlab-container span,
.healthlab-container p {
  line-height: 1.5em !important;
  letter-spacing: -0.04em !important;
}

[data-rsbs-overlay] {
  max-width: 560px;
  margin: 0 auto;
  z-index: 999;
}

[data-rsbs-backdrop] {
  z-index: 999;
}

[data-rsbs-root]:after {
  max-width: 560px;
  margin: 0 auto;
  z-index: 999;
}

[data-rsbs-header] {
  display: none;
}

[data-rsbs-scroll] {
  overflow: hidden;
}

.dept-hub-html {
  overflow-x: scroll;
}

.dept-hub-html p {
  font-size: 14px;
  color: #474a52;
  font-weight: 400;
  line-height: 22px;
  margin: 0;
}

.dept-hub-html li {
  font-size: 14px;
  color: #474a52;
  font-weight: 400;
  line-height: 22px;
  margin: 8px 0;
}

.dept-hub-html table {
  width: 100%;
  border-collapse: collapse;
}

.dept-hub-html th {
  height: 18px;
  padding: 8px 12px;
  background-color: #f1f2f4;
  border: 1px solid #f1f2f4;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  text-align: center;
  color: #474a52;
  max-width: 100px;
}

.dept-hub-html td {
  height: 18px;
  padding: 8px 12px;
  background-color: white;
  border-bottom: 1px solid #f1f2f4;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  color: #474a52;
  max-width: 100px;
}

.dept-hub-html figure {
  margin: 10px 0 0 0;
  overflow-x: scroll;
}
